import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import Box from 'components/box';
import Title from 'components/title';
import profile from '../../service';
import { StaticQuery, graphql } from 'gatsby';

import { Link } from 'gatsby';

import ReactSpeedometer from "react-d3-speedometer"
class ServiceScore extends React.Component {
  render() {
    var service = null;
    if (this.props.data) service = this.props.data.service;

    //service.pdu_score = (1-service.score) * 100;var
    //var score = (service.pdu_score) ; //algorithm function TODO - consolidate
    // var score = (service.score*100).toFixed(0);

    var score = 67;
    var scoreMeter = 1;
    if (service) {
      score = service.score;
      if (score == undefined)
        score = '';
      scoreMeter = score;
    }
    else {
      score = '';
      scoreMeter=20
    }

    return (
      <div className="col-xs-auto">
        {/*  <div className="container">
              <div className="row">
                <div className="col">&nbsp;
                  </div>
                <div className="col">&nbsp;
                  </div>
                  </div>
                  </div> */}
        <div className="border1 border-dark1 col-auto text-center">
          {/* <!-- <img className="img-fluid" styleC="height: unset !important;" height="100" src="/images/dpt-score-warning.png" /> --> */}

          <span className="text-muted text-center" style={{ fontSize: '17px' }}>
            <a
            href="/scoring/" 
            data-toggle="modal" data-target="#privacyTrustModal"
            >
              {/* Digital Trust Score */}
              Digital Trust Score
            </a>
            <br />
          </span>

          {/* {service.!-- <div id="preview-textfield" className="preview-textfield reset" styleC="font-size: 20px ">&nbsp;</div>
                        --} */}
          <div
            // id="preview-textfield"
            className="preview-textfield reset"
            style={{ fontSize: (score>0 ? '37px': '16px') }}
          >
            <span className="badge badge-pill badge-dark">
              {/* {service.#if @score} */}
              {/* <!--//TODO --> */}

              {/* {@score} */}
              {/* {service.else} */}
              {(score>0 ? score : 'Need more data')}

              {/* {service./if} */}
            </span>
          </div>
          {/* <canvas width="175" height="90" id="gauge-dus"></canvas> */}

          {/* <MetricGauge
                    width="175"
                    height="50"
                    needleTransitionDuration={4000}
                    needleTransition="easeElastic"
                    segments="5"
                    minValue="0"
                    maxValue="100"
                    value={ score}
                  /> */}
        
   
        {score!='' || 1==1 ? (
                <div
                className="container"
                style={{ paddingLeft: '15px', paddingRight: '25px' }}
              >
                <div className="row">
                  <div
                    className="col-xs-auto score-low"
                    style={{
                      width: '15px',
                      float: 'right',
                      paddingTop: '60px',
                      marginRight: '20px',
                      zIndex: 100,
                    }}
                  >
                    <span className="badge badge-danger">LOW</span>
                  </div>
                  <div
                    className="col-xs-auto"
                    style={{ width: '150px', height: '90px' }}
                  >
                    {' '}
                    <ReactSpeedometer
                      fluidWidth
                      ringWidth={20}
                      // needleTransitionDuration={4000}
                      // needleTransition="easeElastic"
                      minValue={0}
                      maxValue={100}
                      needleColor="black"
                      startColor= {(score!='' ? '#dc3545': '#eee')}
                      segments={5}
                      endColor={(score!='' ? '#28a745': '#eee')}
                      textColor="white"
                      value={scoreMeter}
                    />
                  </div>
                  <div
                    className="col-xs-auto score-high"
                    style={{
                      width: '15px',
                      float: 'left',
                      paddingTop: '60px',
                      zIndex: 100,
                    }}
                  >
                    <span className="badge badge-success">HIGH</span>
                  </div>
                </div>
    
                {/* <ReactGauge {...options} /> */}
              </div> 
            ) : (
              <>  
              
              </>
            )}
   </div>
       
      </div>
    );
  }
}

ServiceScore.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ServiceScore;
