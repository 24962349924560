import React from 'react'
import { graphql } from 'gatsby'

import Layout from 'components/layout';
import Box from 'components/box';
import Title from 'components/title';
 

export default class ProfileDetail extends React.Component {

  render() {
    const service = this.props.data.service;
    return (
      <div>
      <Layout>
        <Box>
        <h1>{service.name}</h1>
        <div>Logo: </div>
        <div>ID: {service.id}</div>
        <div>Key: {service.key}</div>
        <div>Slug: {service.slug}</div>
        <div>Score: {service.score}</div>
        <div>Age: {service.age}</div>


        </Box>
        </Layout>
      </div>
    );
  }

}
 