import React, { Component, Fragment } from "react"

import { StaticQuery, graphql, Link } from "gatsby"
import Layout from "../components/layout.js"
import SEO from "../components/seo-component.js"

import Helmet from "react-helmet"
const site = {}
import "./about.css"

site.title = process.env.REACT_APP_NAME
// var title = process.env.
const AboutPage = ({ data }) => (
  <StaticQuery
    query={graphql`
      query AboutPageQuery {
        site {
          siteMetadata {
            title
            url
            blogUrl
            appUrl
            getStartedUrl
            twitterUrl
            linkedInUrl
            feedbackUrl
            description
            homeDescription
            homeDescription2
            homeDescription3
            footerDescription
          }
        }
      }
    `}
    render={data => {
      return (
        <Layout>
          <Helmet>
            <body className="about"></body>
          </Helmet>

          <SEO title="About" />
          {/* 
    <div className="page-header">
  <h2> About {site.title}</h2>
</div> */}

          {/* 

    <div className="features2 features-32">
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-md-offset-1 col-sm-12 text-center">  
          
          <br />
                      <br />
                      <br />
     
          </div>
        </div>
       
      </div>
    </div> */}
          <>
            {/* <!-- Top navigation --> */}
            {/* <nav className="navbar navbar-expand-md fixed-top top-nav">
	<div className="container">
		  <a className="navbar-brand" href="#"><strong>Grafreez</strong></a>
		  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
		    <span className="navbar-toggler-icon"><i className="fa fa-bars" aria-hidden="true"></i></span>
		  </button>

		  <div className="collapse navbar-collapse" id="navbarSupportedContent">
		    <ul className="navbar-nav ml-auto">
		      <li className="nav-item active">
		        <a className="nav-link" href="#">Home <span className="sr-only">(current)</span></a>
		      </li>
		      <li className="nav-item">
		        <a className="nav-link" href="#">About</a>
		      </li>
		      <li className="nav-item">
		        <a className="nav-link" href="#">Services</a>
		      </li>
		      <li className="nav-item">
		        <a className="nav-link" href="#">Team</a>
		      </li>
		      <li className="nav-item">
		        <a className="nav-link" href="#">Prices</a>
		      </li>
		      <li className="nav-item">
		        <a className="nav-link" href="#">FAQ's</a>
		      </li>
		      <li className="nav-item">
		        <a className="nav-link" href="#">Contact</a>
		      </li>
		    </ul>
		  </div>	
	</div>
</nav> */}

            {/* <!-- Info block 1 --> */}
            {/* <section className="info-section">
      <div className="container">
        <div className="head-box text-center mb-5">
          <h2>Who We Are</h2>
          <h6 className="text-underline-primary">This is information panel</h6>
        </div>
        <div className="three-panel-block mt-5">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="service-block-overlay text-center mb-5 p-lg-3">
                <i
                  className="fa fa-laptop box-circle-solid mt-3 mb-3"
                  aria-hidden="true"
                />
                <h3>Responsive Design</h3>
                <p className="px-4">
                  Never in all their history have men been able truly to
                  conceive of the world as one a single sphere
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="service-block-overlay text-center mb-5 p-lg-3">
                <i
                  className="fa fa-calendar box-circle-solid mt-3 mb-3"
                  aria-hidden="true"
                />
                <h3>Integrated Calendar</h3>
                <p className="px-4">
                  Never in all their history have men been able truly to
                  conceive of the world as one a single sphere
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="service-block-overlay text-center mb-5 p-lg-3">
                <i
                  className="fa fa-bug box-circle-solid mt-3 mb-3"
                  aria-hidden="true"
                />
                <h3>Bug Free Solutions</h3>
                <p className="px-4">
                  Never in all their history have men been able truly to
                  conceive of the world as one a single sphere
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="service-block-overlay text-center p-lg-3">
                <i
                  className="fa fa-cloud-upload box-circle-solid mt-3 mb-3"
                  aria-hidden="true"
                />
                <h3>Cloud Storage</h3>
                <p className="px-4">
                  Never in all their history have men been able truly to
                  conceive of the world as one a single sphere
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="service-block-overlay text-center p-lg-3">
                <i
                  className="fa fa-diamond box-circle-solid mt-3 mb-3"
                  aria-hidden="true"
                />
                <h3>Premium Features</h3>
                <p className="px-4">
                  Never in all their history have men been able truly to
                  conceive of the world as one a single sphere
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="service-block-overlay text-center p-lg-3">
                <i
                  className="fa fa-comments box-circle-solid mt-3 mb-3"
                  aria-hidden="true"
                />
                <h3>24/7 Support</h3>
                <p className="px-4">
                  Never in all their history have men been able truly to
                  conceive of the world as one a single sphere
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> */}

            {/* <!-- Info block 1 --> */}
            {/* <section className="info-section bg-primary py-0">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 col-lg-6 content-half mt-md-0 pl-5 pt-4">
            <div className="head-box mb-5 pl-5 mt-2">
              <h2 className="text-white">Our Story</h2>
              <h6 className="text-white text-underline-rb-white">
                This is information panel
              </h6>
            </div>
            <ul className="pl-5">
              <li>
                <i className="fa fa-laptop box-round-outline" aria-hidden="true" />
                <span className="list-content">
                  <strong>Responsive Design</strong>
                  <br />
                  Eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </span>
              </li>
              <li>
                <i
                  className="fa fa-cloud-upload box-round-outline"
                  aria-hidden="true"
                />
                <span className="list-content">
                  <strong>Cloud Storage</strong>
                  <br />
                  Eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </span>
              </li>
              <li>
                <i className="fa fa-diamond box-round-outline" aria-hidden="true" />
                <span className="list-content">
                  <strong>Premium Features</strong>
                  <br />
                  Eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </span>
              </li>
            </ul>
          </div>
          <div className="col-md-6 p-0 m-0">
            <img
              src="https://grafreez.com/wp-content/temp_demos/suffi/img/intro-bg.jpg"
              className="img-fluid"
            />
          </div>
        </div>
      </div>
    </section>
   */}

            {/* <!-- team style 1 --> */}
            {/* <section className="team-section py-5">
      <div className="container">
        <div className="row mb-5">
          <div className="head-box text-center mb-5 col-md-12">
            <h2>Meet Our Team</h2>
            <h6 className="text-underline-primary mb-5">
              This is information panel
            </h6>
          </div>
          <div className="col-md-4 mb-md-0 mb-sm-4">
            <div className="member-box anim-lf t-bottom">
              <img
                className="img-fluid"
                src="https://grafreez.com/wp-content/temp_demos/suffi/img/t-member-01.jpg"
                alt=""
              />
              <div className="overlay-content">
                <h3 className="text-white ml-3 my-0">Jim Gorden</h3>
                <p className="text-white ml-3 mb-3">Developer</p>
                <span className="socail-l ml-3 mb-3">
                  <a href="#" className="mr-2">
                    <i className="fa fa-facebook box-circle-solid" />
                  </a>
                  <a href="#" className="mr-2">
                    <i className="fa fa-twitter box-circle-solid" />
                  </a>
                  <a href="#">
                    <i className="fa fa-dribbble box-circle-solid" />
                  </a>
                </span>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-md-0 mb-sm-4">
            <div className="member-box anim-lf t-bottom">
              <img
                className="img-fluid"
                src="https://grafreez.com/wp-content/temp_demos/suffi/img/t-member-02.jpg"
                alt=""
              />
              <div className="overlay-content">
                <h3 className="text-white ml-3 my-0">Peyton Warren</h3>
                <p className="text-white ml-3 mb-3">UI/UX</p>
                <span className="socail-l ml-3 mb-3">
                  <a href="#" className="mr-2">
                    <i className="fa fa-facebook box-circle-solid" />
                  </a>
                  <a href="#" className="mr-2">
                    <i className="fa fa-twitter box-circle-solid" />
                  </a>
                  <a href="#">
                    <i className="fa fa-dribbble box-circle-solid" />
                  </a>
                </span>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-md-0 mb-sm-4">
            <div className="member-box anim-lf t-bottom">
              <img
                className="img-fluid"
                src="https://grafreez.com/wp-content/temp_demos/suffi/img/t-member-03.jpg"
                alt=""
              />
              <div className="overlay-content">
                <h3 className="text-white ml-3 my-0">Craig Thompson</h3>
                <p className="text-white ml-3 mb-3">Manager</p>
                <span className="socail-l ml-3 mb-3">
                  <a href="#" className="mr-2">
                    <i className="fa fa-facebook box-circle-solid" />
                  </a>
                  <a href="#" className="mr-2">
                    <i className="fa fa-twitter box-circle-solid" />
                  </a>
                  <a href="#">
                    <i className="fa fa-dribbble box-circle-solid" />
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    */}

            {/* <Head pageTitle={data.aboutJson.title} /> */}

            <main role="main">
              {/* <!-- Marketing messaging and featurettes */}
              {/* ================================================== --> */}
              {/* <!-- Wrap the rest of the page in another container to center all the content. --> */}

              {/* <!-- /.container --> */}

              {/* <MyNetwork /> */}

              {/* <div
          dangerouslySetInnerHTML={
            __html: data.aboutJson.content.childMarkdownRemark.html,
          }
        /> */}

              {/* <ReactReveal
      name="About"
      sections={sections}
      reveal={revealConfig}
    /> */}
              {/* <Affix/> */}
              {/* <section
        className="features features-2"
        style={{ backgroundColor: 'black', height: '50vh' }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xs-12 text-center">
             
            </div>
          </div>
        </div>
      </section> */}
              {process.env.GATSBY_ACTIVE_ENV == "demo" ||
              process.env.REACT_APP_BUILD_ENV == "demo" ||
              process.env.NODE_ENV == "development" ? (<div>
                <div className="jumbotron page-header animated bounceInRight text-center bg-white"> 
                 {/* <div className="jumbotronn page-header bg-black animated bounceInRight"> */}
                 <ContentSlider /> 
                       </div>
                         <div className="page-header animated bounceInRight text-center bg-white"> 
                    <ContentIntro />
                    <ContentIntroStats />
                            <People />
                            <BelowTheFold />


                            <ContentWeAre />
                            <ContentVision />
                            <ContentMission />
                            <ContentCareers />
               
                       </div>
                     
                {/* </div> */}
                </div>
              ) : (
                <></>
              )}
            </main>
          </>

          <div className="container">
            <div className="targets">
              <div className="title">
                {/* <h2>Solving Data Transparency</h2> */}
                <h2>What We Do</h2>
              </div>

              <div className="row">
                <div className="col-md-6 ">
                  <div className="item">
                    {/* <img alt="Information"} src={informationImage}/> */}
                    <h2>People</h2>
                    <br />
                    <p>
                      Reading user agreements and privacy policies are not a
                      good use of time or effective in vetting privacy and
                      security protections. {site.title} allows people to find
                      and analyze consented policies, agreements, track record
                      and related information free of charge in addition to
                      ongoing monitoring.
                    </p>
                  </div>
                </div>

                <div className="col-md-6 ">
                  <div className="item">
                    {/* <img alt="Event"} src={eventImage}/> */}
                    <h2>Business</h2>
                    <br />
                    <p>
                      How to maintain compliance and assurances is one of the
                      major problems of companies that collect or process
                      personal and privacy data especially with a growing world
                      of fragmented standards and regulations. {site.title}{" "}
                      allows organizations, their procurement teams, and their
                      third party vendors to get transparent information for
                      data protection standards.
                    </p>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="item">
                    {/* <img alt="Productivity"} src={ProductivityImage}/> */}
                    <h2>Auditors</h2>
                    <br />
                    <p>
                      Currently auditors and assessors of various privacy and
                      cybersecurity standards store this information in a
                      fragmented way and often it's not quick or easy to find
                      this information. It's often not clear if an auditor
                      really has certified a tech company for a standard or
                      when. {site.title} provides a trusted place for auditors
                      and companies to disclose and showcase the track record
                      and history of compliance audits.
                    </p>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="item">
                    {/* <img alt="Happiness"} src={happinessImage}/> */}
                    <h2>Authorities</h2>
                    <br />
                    <p>
                      Governments, law enforcement and regulators are often
                      addressing and enforcing laws often after a major data
                      breach has occurred which affects people and customers
                      often too late after damage is done. {site.title} provides
                      useful data since these organizations are often lacking
                      insights to effectively monitor the trust and
                      accountability of target businesses and organizations.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center pb-5 pt-5 mt-6 mb-6 mb64 mt64">
                <Link to="/why" className="btn btn-primary  btn-sm">
                  Learn More about Why {site.title} Exists?
                </Link>
              </div>
            </div>
          </div>
        </Layout>
      )
    }}
  />
)

export default AboutPage

import JSONData from "../../content/people.json"
import SliderJSONData from "../../content/homeSlider.json"
import SliderTweetsJSONData from "../../content/tweets.json"
import NewsTicketJSONData from "../../content/newsTicker.json"
import Image from "react-bootstrap/Image"

import ServiceScore from "../features/services/templates/modules/serviceScore"
const ShowCaseScore = () => (
  <div>
    {" "}
    {/* 
    <br/><br/>
 <h2 className='   text-center'> How?</h2> */}
    <div className="container  text-center">
      {/* <div className='row  justify-content-center'>
        <p style={{ fontWeight: '200' }}>
          Our team is focused on solving the right problems with the right solutions and business models for the future.
        </p>
      </div> */}
      <div className="row  justify-content-center">
        {/*   <br/>
      <br/>
        <br/> */}
        {/* <ServiceScore data={[]} /> */}
      </div>
    </div>
  </div>
)

import {
  Button,
  Container,
  Carousel,
  Table,
  Row,
  Col,
  Nav,
  NavItem,
  Navbar,
  NavbarBrand,
  NavDropdown,
  Form,
  FormControl,
  FormGroup,
  ButtonToolbar,
} from "react-bootstrap"
// import NavbarOffcanvas from 'react-bootstrap-navbar-offcanvas'
const People = () => (
  <div>

<div className="container">
                    <div className="row">
                      <div className="col-md-12">
    
    <h2>{JSONData.title}</h2>

    {/* <h2>{JSONData.title}</h2> */}
    <Carousel data-interval="12000" style={{ color: "#ddd", width: "100%", height: '400px'
    // , verticalAlign: 'middle'
    }}>
      {JSONData.content.map((data, index) => {
        return (
          <Carousel.Item style={{ 
            // height: `fit-content !important`
             }}>
            <Carousel.Caption 
            // style={{ position: `auto !important` }}
            >
              <div key={`2content_item_${index}`}>
                <span
                  className="  text-primary home-testimonial"
                  style={{ fontWeight: "200" }}
                  dangerouslySetInnerHTML={{
                    __html:
                      ' <span className="quote">' +
                      data.item +
                      "</span><br/><br/>" +
                      data.author +
                      "",
                  }}
                ></span>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        )
      })}
    </Carousel>
  </div></div></div></div>
)

const ContentIntro = () => (
  <div> 
    
    <div className="container pt-4 pb-4">
                    <div className="row"> 
                      <div className="col-md-12">
                        <div className=" textGrid ">
                          <div className="animated bounceInRight">
                            <h2 className=" text-center h2 pt-4 pb-4">
                              Choose the right technology to trust
                            </h2>
                            {/* <div className="h3">(every time!)</div> */}
                            <p>
                              We've built a platform that helps you find,
                              discover, and compare technologies and their
                              privacy and cyber resilience standards. For each
                              target, you can find aggregated and self reported
                              information of companies, as well as powerful
                              insights about the company's standards, policies,
                              practices, track record, activity, and more. Armed
                              with all this knowledge, you can make more
                              well-informed decisions and choose the right
                              technology solutions and services to tackle any
                              task you may have.
                              <ShowCaseScore data={[]} />
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    </div>
  </div>
)

const ContentIntroStats = () => (
  <div> 
  <div className="container pt-4 pb-4">
                  <div className="row"> 
       <div className="col-md-12 pt-4 pb-4">
                        {process.env.REACT_APP_BUILD_ENV == "demo" ||
                        process.env.NODE_ENV == "development" || 1==1 ? (
                          <div className=" ">
                            <div className="text-center row  text-primary">
                              <div className="col-md-4   dataItem">
                                <div className="animated fadeIn">
                                  <div className="h1">11.4M+</div>
                                  <div className="h4">
                                    Sites, Apps &amp; Tech Monitored
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4  dataItem">
                                <div className="animated fadeIn">
                                  <div className="h1">716M</div>
                                  <div className="h4">Data Points Analyzed</div>
                                </div>
                              </div>
                              <div className="col-md-4   dataItem">
                                <div className="animated fadeIn">
                                  <div className="h1">478K</div>
                                  <div className="h4">Hours Saved</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
     </div>   </div>
     </div>
)


const ContentWeAre = () => (
  <div> 
  <div className="container pt-4 pb-4">
                  <div className="row"> 
       <div className="col-md-12">  
                              <div className="animated fadeIn">
                                <h2 className="topTitle2 h3 text-center">
                                  We are <strong>{site.title}</strong>
                                </h2>
                                <br />
                                <br />
                                <br />
                                <br />
                              </div> 
                      </div>
     </div> </div>
     </div>
)

const ContentVision = () => (
  <div> 
  <div className="container pt-4 pb-4">
                  <div className="row"> 
       <div className="col-md-12">  
       <div className="  textGrid">
                                  <div className="animated bounceInLeft">
                                    <h2 className=" text-center h2">
                                      Our Vision
                                    </h2>

                                    <p>
                                    DataPolicyTrust is a
                                      service & platform that is helping to
                                      build a better, smarter and safer Internet
                                      for all.
                                    </p>

                                    <br />
                                    <br />
                                  </div>
                                </div>
                      </div>
     </div> </div>
     </div>
)

const ContentMission = () => (
  <div> 
  <div className="container pt-4 pb-4">
                  <div className="row"> 
       <div className="col-md-12">  
    
       <br />
                                <br />
                                <div className="  textGrid">
                                  <div className="animated bounceInLeft">
                                    <h2 className=" text-center h2">
                                      Our Mission
                                    </h2>
                                    <p>
                                      We're on a mission to help customers
                                      choose among millions of technology
                                      services and solutions. In a world with so
                                      many digital choices, we want to ensure
                                      customers find the right options quickly
                                      when it comes to digital trust, privacy
                                      &amp; security practices.
                                    </p>
                                    <br />  
                                  </div>
                                </div>

                                <div className="whyBlock">
                                  <div className=" ">
                                    <div className=" ">
                                      <div className="animated bounceInLeft">
                                        <h2 className="h2 text-center">
                                          <Link
                                            to="/our-story/"
                                            className=" btn btn-primary"
                                          > Our Story
                                          </Link> &nbsp;
                                          <Link
                                            to="/why"
                                            className=" btn btn-primary"
                                          > Why DataPolicyTrust?
                                          </Link> &nbsp;
                                          <Link
                                            to="/company"
                                            className=" btn btn-primary"
                                          > Company
                                          </Link>
                                        </h2>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <br />
                                <br />
                      </div>
     </div> </div>
     </div>
)
const ContentFeedback = () => (
  <div> 
  <div className="container pt-4 pb-4">
                  <div className="row"> 
       <div className="col-md-12">  
       <div className=" d-none">
                            <div className=" ">
                              <div className=" ">
                                <div className=" ">
                                  <svg
                                    id="feedback-icon_svgr__prefix__feedback_icon"
                                    width="42.785"
                                    height="38.272"
                                    data-name="feedback icon"
                                    className=" "
                                  >
                                    <defs>
                                      {/* <style>.feedback-icon_svgr__prefix__cls-1{fill:#11ece5}</style> */}
                                    </defs>
                                    <g
                                      id="feedback-icon_svgr__prefix__Group_1549"
                                      data-name="Group 1549"
                                    >
                                      <g
                                        id="feedback-icon_svgr__prefix__Group_1548"
                                        data-name="Group 1548"
                                      >
                                        <path
                                          id="feedback-icon_svgr__prefix__Path_942"
                                          d="M40.278 41.54h-5.265V29.507A2.51 2.51 0 0032.506 27H10.278a2.51 2.51 0 00-2.507 2.507v7.521H2.507A2.51 2.51 0 000 39.535V54.91a2.51 2.51 0 002.507 2.507h7.425l3.1 3.1a.836.836 0 001.182 0l1.331-1.331v.239a2.51 2.51 0 002.505 2.505h7.425l3.1 3.1a.836.836 0 001.182 0l3.1-3.1h7.425a2.51 2.51 0 002.507-2.507V44.047a2.51 2.51 0 00-2.511-2.507zM9.443 29.507a.837.837 0 01.836-.836h22.227a.837.837 0 01.836.836v15.376a.837.837 0 01-.836.836h-7.771a.836.836 0 00-.591.245l-2.752 2.752-2.752-2.752a.836.836 0 00-.591-.245h-7.771a.837.837 0 01-.836-.836zm6.93 26.484l-2.752 2.752-2.752-2.752a.836.836 0 00-.591-.245H2.507a.837.837 0 01-.836-.836V39.535a.837.837 0 01.836-.836h5.264v6.184a2.51 2.51 0 002.507 2.507H17.7l3.1 3.1a.836.836 0 001.182 0l3.1-3.1h.49v7.52a.837.837 0 01-.836.836h-7.772a.836.836 0 00-.591.245zm24.741 3.432a.837.837 0 01-.836.836h-7.772a.836.836 0 00-.591.245l-2.752 2.752-2.751-2.756a.836.836 0 00-.591-.245H18.05a.837.837 0 01-.836-.836v-1.91l.1-.1h7.425a2.51 2.51 0 002.507-2.507V47.39h5.265a2.51 2.51 0 002.507-2.507v-1.672h5.265a.837.837 0 01.836.836z"
                                          className="feedback-icon_svgr__prefix__cls-1"
                                          data-name="Path 942"
                                          transform="translate(0 -27)"
                                        />
                                      </g>
                                    </g>
                                    <g
                                      id="feedback-icon_svgr__prefix__Group_1551"
                                      data-name="Group 1551"
                                      transform="translate(12.798 4.68)"
                                    >
                                      <g
                                        id="feedback-icon_svgr__prefix__Group_1550"
                                        data-name="Group 1550"
                                      >
                                        <path
                                          id="feedback-icon_svgr__prefix__Path_943"
                                          d="M169.514 83h-15.53a.836.836 0 000 1.671h15.531a.836.836 0 100-1.671z"
                                          className="feedback-icon_svgr__prefix__cls-1"
                                          data-name="Path 943"
                                          transform="translate(-153.148 -83)"
                                        />
                                      </g>
                                    </g>
                                    <g
                                      id="feedback-icon_svgr__prefix__Group_1553"
                                      data-name="Group 1553"
                                      transform="translate(28.328 9.359)"
                                    >
                                      <g
                                        id="feedback-icon_svgr__prefix__Group_1552"
                                        data-name="Group 1552"
                                      >
                                        <path
                                          id="feedback-icon_svgr__prefix__Path_944"
                                          d="M340.426 139.245a.835.835 0 10.245.591.842.842 0 00-.245-.591z"
                                          className="feedback-icon_svgr__prefix__cls-1"
                                          data-name="Path 944"
                                          transform="translate(-339 -139)"
                                        />
                                      </g>
                                    </g>
                                    <g
                                      id="feedback-icon_svgr__prefix__Group_1555"
                                      data-name="Group 1555"
                                      transform="translate(12.798 9.359)"
                                    >
                                      <g
                                        id="feedback-icon_svgr__prefix__Group_1554"
                                        data-name="Group 1554"
                                      >
                                        <path
                                          id="feedback-icon_svgr__prefix__Path_945"
                                          d="M165.365 139h-11.381a.836.836 0 000 1.671h11.381a.836.836 0 100-1.671z"
                                          className="feedback-icon_svgr__prefix__cls-1"
                                          data-name="Path 945"
                                          transform="translate(-153.148 -139)"
                                        />
                                      </g>
                                    </g>
                                    <g
                                      id="feedback-icon_svgr__prefix__Group_1557"
                                      data-name="Group 1557"
                                      transform="translate(16.886 14.039)"
                                    >
                                      <g
                                        id="feedback-icon_svgr__prefix__Group_1556"
                                        data-name="Group 1556"
                                      >
                                        <path
                                          id="feedback-icon_svgr__prefix__Path_946"
                                          d="M214.349 195h-11.442a.836.836 0 000 1.671h11.442a.836.836 0 100-1.671z"
                                          className="feedback-icon_svgr__prefix__cls-1"
                                          data-name="Path 946"
                                          transform="translate(-202.071 -195)"
                                        />
                                      </g>
                                    </g>
                                    <g
                                      id="feedback-icon_svgr__prefix__Group_1559"
                                      data-name="Group 1559"
                                      transform="translate(12.785 14.039)"
                                    >
                                      <g
                                        id="feedback-icon_svgr__prefix__Group_1558"
                                        data-name="Group 1558"
                                      >
                                        <path
                                          id="feedback-icon_svgr__prefix__Path_947"
                                          d="M153.906 195h-.07a.836.836 0 100 1.671h.07a.836.836 0 100-1.671z"
                                          className="feedback-icon_svgr__prefix__cls-1"
                                          data-name="Path 947"
                                          transform="translate(-153 -195)"
                                        />
                                      </g>
                                    </g>
                                  </svg>
                                  Send us feedback
                                </div>
                                <div className=" ">
                                  <div className=" ">
                                    Got any suggestion, bug report, or question
                                    about
                                    {site.title}?
                                  </div>
                                  <form>
                                    <div className=" ">
                                      <textarea name="message" rows="5" />
                                    </div>
                                    <div className=" ">
                                      <div className=" ">
                                        <label className=" ">
                                          Name (optional):{" "}
                                        </label>
                                        <input
                                          type="text"
                                          name="name"
                                          value=""
                                        />
                                      </div>
                                      <div className=" ">
                                        <label className=" ">
                                          Email (optional):
                                        </label>
                                        <input
                                          type="text"
                                          name="email"
                                          className=" "
                                          value=""
                                        />
                                      </div>
                                    </div>
                                    <div tabindex="0" className=" ">
                                      <input
                                        multiple=""
                                        type="file"
                                        autocomplete="off"
                                        tabindex="-1"
                                        // style="display: none;"
                                      />
                                      <p>
                                        Click to select files or drag &amp; drop
                                        here
                                      </p>
                                      <div />
                                    </div>
                                    <div className=" ">
                                      <button
                                        type="submit"
                                        className="sc-fznzOf bMMhyo"
                                      >
                                        <div className=" ">Send Feedback</div>
                                      </button>
                                    </div>
                                  </form>
                                  <div className=" " />
                                </div>
                                <div className=" ">
                                  <span className=" ">
                                    <a
                                      href="mailto:support@DataPolicyTrust.com?subject=DataPolicyTrust Feedback"
                                      target="_blank"
                                      rel="nofollow noopener"
                                    >
                                      Email us
                                    </a>
                                  </span>
                                  <span className=" ">Cancel</span>
                                </div>
                              </div>
                            </div>
                          </div>
                     
                      </div>
     </div> </div>
     </div>
)
const ContentCareers = () => (
  <div> 
  <div className="container pt-4 pb-4">
                  <div className="row"> 
       <div className="col-md-12">  
       <div className="careersBlock">
                              <div className=" ">
                                <div className=" ">
                                  <div className="animated bounceInLeft">
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <h2 className="h2 text-center">
                                      Careers at {site.title}
                                    </h2>
                                    <p>
                                      We are currently recruiting our core team.
                                      We're a remote-first company, so it
                                      doesn't matter where you are in the world.
                                      Join us as one of the first employees for
                                      a great startup culture and energy, a
                                      generous stock options grant, a lot of
                                      impact and autonomy, and super-interesting
                                      challenges. You’ll have significant
                                      influence over {site.title}' product and
                                      the roadmap, and build something that
                                      would improve life for millions of
                                      customers and vendors.
                                      <br />
                                      <br />
                                    </p>
                                    <div className="alignCenter text-center">
                                      
                                    <Link
                                            to="/contact"
                                            className=" btn btn-primary"
                                          > Check out our open positions
                                          </Link>
                                     
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                      </div>
     </div> </div>
     </div>
)
const ContentSlider = () => (
  <div>
  <div className="container">
    <div className="row"> 
      <div className="col-md-12"> 
    <div
      id="carouselAboutIndicators"
      className="carousel slide d-none2"
      data-ride="carousel"
    >
      <ol className="carousel-indicators">
        <li
          data-target="#carouselAboutIndicators"
          data-slide-to="0"
          className="active"
        ></li>
        <li data-target="#carouselAboutIndicators" data-slide-to="1"></li>
        <li data-target="#carouselAboutIndicators" data-slide-to="2"></li>
      </ol>
      <div className="carousel-inner text-primary">
        <div className="carousel-item active">
          {/* <img className="d-block w-100" src="..." alt="First slide"/> */}
          <h3>Work, learn &amp; communicate more safely online</h3>
        </div>
        <div className="carousel-item">
          {/* <img className="d-block w-100" src="..." alt="Second slide"/> */}

          <h3>Monitoring Data Privacy Globally.</h3>
          <p>
            {/* Tracking over 300M websites, 35M softwares, 10M apps, 54M services &amp; 100M organizations */}
          </p>
          {/* <Button variant='primary' href='/consumers'>
                Learn more
              </Button> */}
        </div>
        <div className="carousel-item">
          {/* <img className="d-block w-100" src="..." alt="Third slide"/> */}{" "}
          <h3>
            Digital trust, cyber resilience, cybersecurity, privacy, policies and more...
          </h3>
          {/* <p>
            Why is there is no consumer privacy standard yet? That's too
            confusing for people and business?
          </p> */}
          {/* <Button variant='primary' href='/consumers'>
                Learn more
              </Button> */}
        </div>
        <div className="carousel-item">
          {/* <img className="d-block w-100" src="..." alt="Third slide"/> */}{" "}
          <h3>A global digital trust and transparency platform for all.</h3>
          
        </div>
        <div className="carousel-item">
          {/* <img className="d-block w-100" src="..." alt="Third slide"/> */}{" "}
          <h3>.</h3>  
        </div>
        <div className="carousel-item">
          {/* <img className="d-block w-100" src="..." alt="Third slide"/> */}{" "}
          <h3>Help every person & organization in the world do more online.</h3>
         
        </div>
        <div className="carousel-item">
          {/* <img className="d-block w-100" src="..." alt="Third slide"/> */}{" "}
          <h3>Buliding a better, smarter and safer Digital Future.</h3>
       
        </div>
      </div>
      {/* <a
        className="carousel-control-prev"
        href="#carouselAboutIndicators"
        role="button"
        data-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="sr-only">Previous</span>
      </a>
      <a
        className="carousel-control-next"
        href="#carouselAboutIndicators"
        role="button"
        data-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="sr-only">Next</span>
      </a> */}
    </div>

    <div className="container d-none text-black">
      <Carousel
        data-interval="12000"
        className="d-none2 d-md-block"
        style={{ color: "#ddd" }}
      >
        <Carousel.Item>
          <Carousel.Caption>
            <h3>Work, learn &amp; communicate more safely online</h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <Carousel.Caption>
            <h3>Monitoring Data Privacy Globally.</h3>
            <p>
              {/* Tracking over 300M websites, 35M softwares, 10M apps, 54M services &amp; 100M organizations */}
            </p>
            {/* <Button variant='primary' href='/consumers'>
                Learn more
              </Button> */}
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <Carousel.Caption>
            <h3>
              We are redefining digital trust for data privacy and personal
              data.
            </h3>
            <p>
              Why is there is no consumer privacy standard yet? That's too
              confusing for people and business?
            </p>
            {/* <Button variant='primary' href='/consumers'>
                Learn more
              </Button> */}
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <Carousel.Caption>
            <h3>A global privacy platforms for all customers of tech world.</h3>
            <p>
              We help every person & organization in the world do more online --
              better, smarter and safer.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          {/* <Image
                                                                                                      className="d-block w-100"
                                                                                                      src="holder.js/800x200?text=Second slide&bg=282c34"
                                                                                                      alt="Third slide"
                                                                                                    /> */}
          <Carousel.Caption>
            <h3>The world's most trusted authority on data privacy.</h3>
            <p>
              Most people don't have time or do not understand data privacy, but
              we exist to solve that problem for all.
            </p>
            {/* <Button variant='primary' href='/consumers'>
                Learn more
              </Button> */}
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <Carousel.Caption>
            <h3>A public watchdog & monitoring service.</h3>
            <p>
              We monitor every website, product, online plus all data collecting
              and data processing services & also data brokers in the world.
            </p>
            {/* <Button variant='primary' href='/consumers'>
                Learn more
              </Button> */}
          </Carousel.Caption>
        </Carousel.Item>
        {/*     <Carousel.Item>
            <Carousel.Caption>
              <h3>We are helping thwart hackers globally.</h3>
              <p>
                Building a better, smarter and safer Internet and world - reducing phishing, link and other fraud.
              </p>
          <Button variant='primary' href='/consumers'>
                Learn more
              </Button>
            </Carousel.Caption>
          </Carousel.Item> */}
        <Carousel.Item>
          <Carousel.Caption>
            <h3>We provide the first global user privacy dashboard.</h3>
            <p>
              Every user in the world gets their own dashboard, AI-analysis
              assistant, User Privacy Policy and more to contain all their
              online user agreement links and optional monitoring of changes,
              threats, alerts, recommendations and alternatives.
            </p>
            {/* <Button variant='primary' href='/consumers'>
                Learn more
              </Button> */}
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <Carousel.Caption>
            <h3>Creating a better, smarter & safer Internet future!</h3>
            {/* <p>
                {' '} We do not think waiting for the regulators and auditors solve it as it is often too late or waiting til the next data breach is enough.
              </p> */}
            {/* <Button variant='primary' href='/consumers'>
                Learn more
              </Button> */}
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
    </div>
                      </div>
                    </div></div>
)

const SliderTweets = () => (
 
  <div className="container">
  <div className="row"> 
  <div className="col-md-12"> 
  <div
    className="d-none2 d-lg-block tweets" 
     style={{ maxWidth: `960px` , margin: `1.45rem` }}

  >
    <h2>{SliderTweetsJSONData.title}</h2>
    <Carousel 
      data-interval="15000"
      style={{ textAlign: "center", color: "#fff" }}
    >
      {SliderTweetsJSONData.content.map((data, index) => {
        return (
          <Carousel.Item>
            <Carousel.Caption
              // style={{  position: `auto !important` }}
            > 
              <center>
                <TweetEmbed
                  id={data.id}
                  // theme="dark"
                  // options={{ theme: "dark" }}
                />
              </center> 
            </Carousel.Caption>
          </Carousel.Item>
        )
      })}
    </Carousel>
  </div></div></div></div>
)

// import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';
import TweetEmbed from "react-tweet-embed"
const BelowTheFold = () => (
  
  <div className="container">
  <div className="row"> 
  <div className="col-md-12"> 
<Helmet>
    <script
      async
      src="https://platform.twitter.com/widgets.js"
      charset="utf-8"
    ></script>
    </Helmet>
    <SliderTweets />
  <div>
    <h2>The Internet Needs You</h2>
    <br />
    <h4>Help improve the Internet</h4>
    <p>
      Suggest a <a href="/suggest/">service, app or technology</a>.
    </p>
    <p>
      Register as a <a href="/providers">provider organization</a>.
    </p>
    <p>
      Register your <a href="/organizations">teams &amp; staff</a>.
    </p>
    <br />
    {/* <hr/> */}
    <br />
    <br />
    {/* <TwitterTweetEmbed
  tweetId={'933354946111705097'}
/> */}
    {/* <TweetEmbed id='1157283275729039360'  */}
    {/* // options={{theme: 'dark' }} */}

    {/* /> */}

    {/* <blockquote className="twitter-tweet"><p lang="en" dir="ltr">Totally agree. Technology needs to be slowed down via smart regulation, more evolved &amp; principled business models and better policies. It’s why we are happy to share <a href="https://t.co/qEZSDZPCJF">https://t.co/qEZSDZPCJF</a> to ensure all tech companies are more transparent with their practices &amp; policies.</p>&mdash; Omar Uddin (@omaruddin) <a href="https://twitter.com/omaruddin/status/1157283275729039360?ref_src=twsrc%5Etfw">August 2, 2019</a></blockquote>  */}

    <br />
    <br />
  </div>

  </div></div></div>

)
/* 
[
                                              {
                                                text:
                                                  'Facebook is making its biggest executive shuffle in company',
                                                link:
                                                  'https://www.vox.com/2018/5/8/17330226/facebook-reorg-mark-zuckerberg-whatsapp-messenger-ceo-blockchain',
                                              },
                                              {
                                                text:
                                                  'Suspending Cambridge Analytica and SCL Group From Facebook',
                                                link:
                                                  'https://newsroom.fb.com/news/2018/03/suspending-cambridge-analytica/',
                                              },
                                              {
                                                text: 'What the FTC Facebook settlement means for consumers',
                                                link:
                                                  'https://www.consumer.ftc.gov/blog/2019/07/what-ftc-facebook-settlement-means-consumers',
                                              },
                                            ] */

class FeaturedAnimation extends Component {
  state = {
    showFeatures: false,
  }

  showFeatures = () => {
    this.setState({ showFeatures: true })
  }

  render() {
    return (
      <div className={stylesAnimation.page}>
        <TypingAnimation
          speed={50}
          startDelay={1000}
          className={stylesAnimation.title}
          onFinishedTyping={this.showFeatures}
          onStartedTyping={() => console.log("started typing")}
          onBeforeType={text => console.log("onBeforeType", text)}
          onAfterType={text => console.log("onAfterType", text)}
        >
          <h1>
            <a href=" ">Typing Animation</a>
            <Delay ms={500} />
            <span className={stylesAnimation.dependencies}>
              * zero dependencies
            </span>
          </h1>
          <Delay ms={1000} />
        </TypingAnimation>

        {this.state.showFeatures && (
          <TypingAnimation loop speed={50} className={stylesAnimation.features}>
            <Delay ms={500} />
            <ul>
              <li>
                <span className={stylesAnimation.checkmark}> &#10004;</span>{" "}
                Type text from any valid JSX.
              </li>
              <Delay ms={1000} />
              <li>
                <span className={stylesAnimation.styledSpan}>
                  <span className={stylesAnimation.checkmark}> &#10004;</span>{" "}
                  All styles and{" "}
                  <table className={stylesAnimation.table}>
                    <tbody>
                      <tr>
                        <td>D</td>
                      </tr>
                      <tr>
                        <td>O</td>
                      </tr>
                      <tr>
                        <td>M</td>
                      </tr>
                      <tr>
                        <td>&nbsp;</td>
                      </tr>
                      <tr>
                        <td>S</td>
                      </tr>
                      <tr>
                        <td>T</td>
                      </tr>
                      <tr>
                        <td>R</td>
                      </tr>
                      <tr>
                        <td>U</td>
                      </tr>
                      <tr>
                        <td>C</td>
                      </tr>
                      <tr>
                        <td>T</td>
                      </tr>
                      <tr>
                        <td>U</td>
                      </tr>
                      <tr>
                        <td>R</td>
                      </tr>
                      <tr>
                        <td>E</td>
                      </tr>
                      <tr>
                        <td>S</td>
                      </tr>
                    </tbody>
                  </table>{" "}
                  will be preserved.
                </span>
              </li>
              <Delay ms={1000} />
              <li>
                <span className={stylesAnimation.checkmark}> &#10004;</span>{" "}
                Change the <Speed ms={250} /> speed <Speed ms={20} /> of typing
                anywhere <Speed ms={40} /> in the tree.
              </li>
              <Delay ms={1000} />
              <li>
                <span className={stylesAnimation.checkmark}> &#10004;</span>{" "}
                Backspace with ease.
                <Backspace count={10} delay={750} />
                {"couldn't be simpler."}
              </li>
              <Delay ms={1000} />
              <li>
                <span className={stylesAnimation.checkmark}> &#10004;</span>{" "}
                Neither could deleting a line of text.
                <Reset count={1} delay={750} />
                <span> Or the entire tree.</span>
                <Reset delay={2000} />
              </li>
            </ul>
          </TypingAnimation>
        )}
      </div>
    )
  }
}
